/* #708238 */
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400;1,600;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

@font-face {
  font-family: "Winkle";
  src: url("assets/Winkle-Regular.ttf") format("truetype");
}

* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input,
textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

section {
  margin: 40px 0;
}

a {
  text-decoration: none;
  color: #708238;
}

li {
  list-style-type: none;
}

ul {
  padding: 0;
}

.cta-button-wrapper {
  margin: 20px 0;
}

.cta-button-wrapper .cta-button {
  margin: 0;
}

.cta-button {
  cursor: pointer;
  padding: 12px 10px;
  background-color: #708238;
  border-radius: 10px;
  color: white;
  margin-left: 20px;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    color: white;
  }
}

.section__description {
  text-align: center;
  max-width: 350px;
  margin: 24px auto;
}

.section__description--para {
  font-weight: 300;
}

.section__description--title {
  text-align: center;
  font-size: 20px;
  color: #708238;
}

.container,
.nav__container {
  height: 100%;
  padding: 0 12px;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 40px;
}

body {
  overflow-x: hidden;
  max-width: 100%;
}

.row {
  height: 100%;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

button {
  cursor: pointer;
}

.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #708238;
  margin: 12px 0;
  font-size: 18px;
}

/*

  PRICES

*/

.prices__wrapper {
  display: flex;
  gap: 24px;
}

.prices__description {
  max-width: 50%;
  width: 100%;
}

.prices__description--title {
  font-size: 24px;
  font-weight: 500;
  color: #708238;
}

.prices__image {
  width: 50%;
  width: 100%;
}

.prices__image--img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/*

  NAV

*/

nav {
  display: flex;
  height: 100px;
}

.nav__container {
  padding: 0 16px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.nav__links {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.logo {
  width: 180px;
  padding: 30px;
}

.nav__link {
  text-decoration: none;
  margin-left: 16px;
  font-weight: 500;
  margin: 0 16px;
  color: #000;
  font-size: 18px;
}

.link__hover-effect {
  position: relative;
}

.link__hover-effect:after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 0;
  right: 0;
  transition: all 300ms ease;
  background-color: #708238;
}

.link__hover-effect:hover:after {
  left: 0;
  width: 100%;
}

.nav__link--primary {
  border: 2px solid #708238;
  border-radius: 4px;
  transition: 300ms ease;
}

.nav__link--primary:hover {
  background-color: #708238;
  color: white;
  transition: 0.3s;
  text-decoration: none;
}

.btn__menu {
  border: none;
  background-color: transparent;
  font-size: 40px;
  color: #708238;
  text-align: center;
  display: none;
  padding: 8px;
}

.menu__link {
  color: #242424;
}

.menu__list:hover .menu__link {
  color: #708238;
  text-decoration: underline;
}

.menu__backdrop {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  z-index: 3;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
}

.menu__links {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu--open .menu__backdrop {
  visibility: visible;
  opacity: 1;
}

.menu__list {
  color: #708238;
  padding: 16px;
  margin: 10px;
  background-color: rgba(108, 214, 66, 0.1);
  border-radius: 4px;
  list-style-type: none;
  font-size: 16px;
  font-weight: bold;
}

.menu__list:hover {
  color: #708238;
  cursor: pointer;
}

.btn__menu--close {
  color: #708238;
}

.menu__logo--img {
  width: 180px;
}

.menu__bar {
  padding: 20px 0;
  margin: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

/*

  LANDING

*/

.landing__banner {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
  height: calc(80vh - 80px);
}

.landing__banner--description {
  padding: 40px;
  width: 50%;
}

.landing__banner--img {
  width: 50%;
  object-fit: cover;
  border-radius: 10px;
}

.landing__footer {
  top: -80px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.landing__description {
  padding: 40px 0 12px 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.landing__description--title {
  color: #708238;
  font-weight: 500;
  font-size: 32px;
}

.landing__description--para {
  font-size: 16px;
  margin: 12px 0;
}

.landing__description--parastrong {
  font-size: 16px;
  font-weight: 500;
}

.landing__description--wrapper {
  margin-top: 80px;
  padding: 8px;
  max-width: 50%;
  width: 100%;
}

.landing__description--img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner__wrapper {
  padding: 0 12px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  color: black;
}

.banner__description--para {
  max-width: 450px;
  font-weight: 400;
}

.banner__description--title {
  font-size: 40px;
  color: black;
  font-weight: 600;
}

.banner__description--title em {
  color: #708238;
}

/*

  FOOTER

*/

.credits {
  text-align: center;
  font-size: 16px;
  color: white;
  font-weight: 300;
}

.credits a {
  color: white;
  text-decoration: underline;
  font-weight: 500;
}

.footer__smalltitle {
  font-size: 16px;
  font-weight: 300;
  color: white;
}

.footer__title {
  font-size: 22px;
  font-weight: 500;
  color: white;
}

.list__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.footer__wrapper {
  display: flex;
  justify-content: space-around;
}

footer {
  padding: 40px 0;
  background-color: #708238;
}

.footer__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.socials__list {
  display: flex;
  gap: 12px;
}

.socials__list--anchor {
  color: #ebf5ec;
  font-size: 32px;
}

.footer__item--anchor {
  font-size: 18px;
  font-weight: 300;
  color: #ebf5ec;
}

.footer__item--anchor:hover {
  text-decoration: underline;
}

/*

  ANIMATION

*/

.fa-spinner {
  animation: spinner 750ms infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*

  CONTACT

*/

.contact__wrapper .info {
  display: flex;
  flex-direction: column;
}

.contact__wrapper .info > a {
  color: #333;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.contact__wrapper .info > a:hover {
  color: #708238;
}

.contact__wrapper .info span {
  color: #708238;
  margin-right: 10px;
}

.contact__overlay {
  width: 100%;
  text-align: center;
  color: #155724;
  font-weight: 300;
  padding: 12px;
  border-radius: 4px;
  margin: 12px 0;
}

.contact__overlay--success,
.contact__overlay--loading {
  display: none;
}

.contact__overlay--loading {
  font-size: 40px;
}

.contact__form--input {
  padding: 8px;
  border: none;
  border-bottom: 2px #708238 solid;
  outline: none;
  width: 100%;
}

.contact__form--textarea {
  height: 150px;
  resize: vertical;
}

.contact__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.contact__map {
  max-width: 50%;
  width: 100%;
}

.contact__form {
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.contact__head {
  margin: 12px 0;
}

.contact__head--title {
  color: #708238;
  font-weight: 500;
}

.contact__head--para {
  font-weight: 300;
  max-width: 400px;
}

/*

  404 NOT FOUND

*/

.notfound {
  padding: 60px 0;
  height: calc(100vh - 80px - 212px);
}

.notfound__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notfound__img {
  width: 500px;
}

/*

  SERVICES

*/

.services__list--icon {
  color: #708238;
  font-size: 48px;
}

.services__title {
  padding: 2px 0;
  font-size: 24px;
}

.services__para {
  font-size: 16px;
  padding: 0 40px;
}

.services {
  margin: 80px 0;
  padding: 0 0 24px 0;
}

.services__list {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
}

@media (max-width: 768px) {
  .btn__menu {
    display: block !important;
  }

  .nav__list {
    display: none;
  }
  .landing__banner {
    height: unset;
    flex-direction: column-reverse;
  }
  .landing__footer {
    display: none;
  }
  .landing__banner--description,
  .landing__banner--img {
    height: 300px;
    object-fit: cover;
    width: 100%;
    padding: unset;
  }
  .landing__description--wrapper {
    margin: unset;
  }
  .banner__wrapper {
    padding: 12px;
  }
  .contact__wrapper {
    flex-direction: column;
  }
  .contact__map {
    max-width: 100%;
  }
  .contact__form {
    max-width: 100%;
  }
  .prices__wrapper {
    flex-direction: column-reverse;
  }
  .prices__description {
    max-width: 100%;
  }
  .landing__description {
    flex-direction: column;
    align-items: center;
  }

  .landing__description--wrapper {
    max-width: 100%;
  }

  .banner__description--para {
    text-align: center;
  }

  .banner__description--title {
    text-align: center;
  }

  .banner__wrapper {
    align-items: center;
  }
}

@media (max-width: 550px) {
  .services__list {
    flex-direction: column;
    gap: 40px;
  }

  .banner__description--para {
    font-size: 16px;
  }

  .banner__description--title {
    font-size: 30px;
  }

  .services__para {
    max-width: 400px;
  }

  .notfound__img {
    width: 100%;
  }
}

@media (max-height: 660px) {
  .menu__backdrop {
    height: 50vh;
  }
}
